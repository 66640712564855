<footer id="footer" class="ym-clearfix">
	<div class="left">
		<bas-footer-copyright></bas-footer-copyright>
	</div>
	<div class="right">
        <bas-footer-faq></bas-footer-faq>
		<bas-footer-terms-of-use></bas-footer-terms-of-use>
		<bas-footer-privacy-policy></bas-footer-privacy-policy>
		<bas-footer-impressum></bas-footer-impressum>
		<bas-footer-contact></bas-footer-contact>
		<bas-footer-history></bas-footer-history>
	</div>
	<div class="bottom">
		<bas-footer-serverinfo></bas-footer-serverinfo>
	</div>
</footer>
